/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.nav-bar-button {
    text-decoration: none;
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;
    padding: 12px;
    
}
.nav-bar-button-active {
    background-color: rgb(230, 230, 230);
    border-bottom: 2px solid var(--bs-primary);
}
.nav-bar-button:hover {
    background-color: rgb(230, 230, 230);
}

.nav-bar-button-mobile {
    text-decoration: none;
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;
    padding: 4px 12px 4px 12px;
    color: var(--bs-primary);
    
}
.nav-bar-button-mobile-active {
    background-color: var(--bs-primary);
    color: white;
}

.nav-bar-button-mobile:hover {
    opacity: 0.66;
}