/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.user-info{
    display: flex;
    justify-content: center;
    gap: 30px;

    @media (max-width: 650px){
        flex-direction: column;
        gap: 0px;
    }
}

.desktop-users{
    display: block;

    @media (max-width: 820px){
        display: none;
    }
}

.mobile-users{
    display: none;

    @media (max-width: 820px){
        display: block;
    }
}

.mobile-list-item-edit:hover{
    background-color: rgb(236,236,236);
}