/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
.summary-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 12px;
    row-gap: 12px;

    @media (max-width: 1700px) {
        grid-template-columns: 1fr 1fr;
    }   

    @media (max-width: 1100px) {
        grid-template-columns: 100%;
    }   
}

.all-time-labels{
    /* style={{display: 'flex', justifyContent: 'center', gap: 24, marginBottom: 24}} */
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 24px;

    @media (max-width: 680px) {
        flex-direction: column;
        gap: 0px;
        align-items: center;
    }   
}