/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.pagination{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    flex-wrap: wrap;
    

    @media (max-width: 1040px) {
        flex-direction: column-reverse;
    }
}

.results-row{
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: 710px) {
        flex-direction: column;
        gap: 12px;
    }
}

.desktop-listings{
    display: block;

    @media (max-width: 1200px) {
        display: none;
    }
}

.mobile-listings{
    display: none;

    @media (max-width: 1200px) {
        display: block;
    }
}

.desktop-btn{
    display: block;

    @media (max-width: 360px) {
        display: none;
    }
}

.itemsperpage-btns{
    height: 40px;
    display: flex;
    align-items: center;

    @media (max-width: 400px) {
        flex-direction: column;
    }

}

.perpage-text{
    margin: 0;
    margin-right: 8px;

    @media (max-width: 400px) {
        margin-right: 0;
        margin-bottom: 4px;
    }
}

.desktop-distancefilter{
    display: flex;

    @media (max-width: 660px) {
        display: none;
    }
}

.mobile-distancefilter{
    display: none;

    @media (max-width: 660px) {
        display: flex;
    }
}